import React, { useState } from 'react';
import i18n from "../i18n";
import { useAuth } from "../context/auth.context";
import { updateEmail, setLoading, updateSettings } from '../actions/auth.action'
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { randomId } from '../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { Link, useNavigate } from "react-router-dom";
import apiService from '../services/api';
import useDocument from '../hooks/useDocument.hooks'


function ForgotPasswordPage() {

    const navigate = useNavigate();
    const { t } = i18n;
    const { email, loading, settings, dispatch } = useAuth();
    const [formValidated, setFormValidated] = useState(false);
    const api = new apiService()
    useDocument({title: t('_page_title.forgot_password')})


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        setFormValidated(false);
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {

            try {

                dispatch(setLoading(true));
                dispatch(updateSettings({ ...settings, error: ''}))

                // Make API call to validate email and send password reset link
                const resp = await api.forgotPassword({ email })

                if (resp.status === 200) {
                    navigate("/auth/resetLinkSent");
                } else {
                    throw new Error();
                }

            } catch (error: any) {

                if (error.response && error.response.data && error.response.data.message) {
                    dispatch(updateSettings({ ...settings, error: error.response.data.message }))
                } else {
                    dispatch(updateSettings({ error: t('_system_error') }))
                }

            } finally {
                dispatch(setLoading(false));
            }
        }
        setFormValidated(true);
    }

    return (
        <>
            <Card.Body>

                <Card.Title className="text-start"><h1>{t('_forgot_password.title')}</h1></Card.Title>
                <Card.Text className="text-start">{t('_forgot_password.description')}</Card.Text>

                <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                        <Form.Control
                            placeholder={t('email')}
                            aria-label="Email"
                            required
                            type="email"
                            onChange={(e) => dispatch(updateEmail(e.target.value))}
                            id={(randomId())}
                            defaultValue={email}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {email ? `${t('invalid_field', { field: t('email') })}: ${email}` : t('field_cannot_be_blank', { field: t('email') })}
                        </Form.Control.Feedback>
                    </InputGroup>

                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
                        {t('continue')}
                    </Button>

                    <div className='text-center mt-2 help-link'><Link to="/auth/" className='text-decoration-none'>{t('return_to_sign_in')}</Link></div>
                </Form>

            </Card.Body>
        </>
    )
}


export default ForgotPasswordPage