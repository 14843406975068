import React from 'react';
import i18n from '../i18n';
import config from '../config';
import LanguageSwitcherComponent from "./languageSwitcher.component";
import { Card } from 'react-bootstrap';

const FooterComponent: React.FC = () => {

  const { t, language } = i18n
  const year = new Date().getFullYear()

  return (
    <>
      <Card.Footer className="text-muted">
        <div className='d-flex justify-content-around align-items-center gap-1 footer-links-container mt-4 mb-4' >
          <a rel="noreferrer" href={`${config.LANDING_PAGE_URL}/?lang=${language}`} target="_blank">{t('home')}</a>
          <a rel="noreferrer" href={`${config.LANDING_PAGE_URL}/public/terms-of-use-internal-end-users/?lang=${language}`} target="_blank">{t('terms_of_use')}</a>
          <a rel="noreferrer" href={`${config.LANDING_PAGE_URL}/public/privacy-policy/?lang=${language}`} target="_blank">{t('privacy_policy')}</a>
          <a rel="noreferrer" href={`${config.LANDING_PAGE_URL}/public/contact-us?lang=${language}`} target="_blank">{t('contact_us')}</a>
        </div>

        <div className='copyright-container'>
          {t('_copyright', { year: year })}
        </div>

        <LanguageSwitcherComponent />

      </Card.Footer>
    </>
  );
};

export default FooterComponent;
