import React, { useState } from 'react';
import { useAuth } from '../../context/auth.context';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { updatePassword, setLoading, updateSettings } from '../../actions/auth.action'
import i18n from '../../i18n';
import {  randomId } from '../../services/utils';
import { Link } from "react-router-dom";
import apiService from '../../services/api';
import { LoginMode } from '../../enums/auth.enum';

const PasswordStepComponent: React.FC = () => {

  const api = new apiService()
  const { t } = i18n
  const { email, password, settings, loading, dispatch } = useAuth();
  const [formValidated, setFormValidated] = useState(false);

  const handleMfa = async () => {
    try {

      dispatch(setLoading(true));
      dispatch(updateSettings({ ...settings, error: '' }))

      // Make API call to validate email
      const resp = await api.validateCredentials({ email, password: password || '' })

      if (resp.status === 200) {
        dispatch(updateSettings({ ...settings, error: '', passwordConfirmed: true }))
      } else {
        throw new Error();
      }

    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        dispatch(updateSettings({ ...settings, error: error.response.data.message }))
      } else {
        dispatch(updateSettings({ error: t('_system_error') }))
      }

    } finally {
      dispatch(setLoading(false));
    }

  }

  const handleLogin = async () => {
    try {

      dispatch(setLoading(true));
      dispatch(updateSettings({ ...settings, error: ''}))

      // Make API call to validate email
      const resp = await api.login({ email, password: password || '' })

      if (resp.status === 200) {
        window.location.reload();
      } else {
        throw new Error();
      }

    } catch (error: any) {

      if (error.response && error.response.data && error.response.data.message) {
        dispatch(updateSettings({ ...settings, error: error.response.data.message }))
      } else {
        dispatch(updateSettings({ error: t('_system_error') }))
      }
      dispatch(setLoading(false));

    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    setFormValidated(false);
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {

      if (settings.loginMode === LoginMode.MFA) {
        handleMfa()

      } else {
        handleLogin()
      }

     
    }
    setFormValidated(true);

  }

  if (!settings.emailConfirmed) {
    return (<></>)
  }

  if (settings.emailConfirmed && settings.passwordConfirmed) {
    return (<></>)
  }

  return (
    <>

      <Form noValidate validated={formValidated} onSubmit={handleSubmit}>


        <InputGroup>
          <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
          <Form.Control
            placeholder={t(['password'])}
            aria-label="password"
            required
            type="password"
            onChange={(e) => dispatch(updatePassword(e.target.value))}
            id={(randomId())}
            disabled={loading}
            readOnly={loading}
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            {password ? `${t('invalid_field', { field: t('password') })}` : t('field_cannot_be_blank', { field: t('password') })}
          </Form.Control.Feedback>
        </InputGroup>

        
        {settings && settings.emailVerified && !loading &&
          <div className='text-end help-link'> <Link to="/auth/forgot" className='text-decoration-none'>{t('forgot_password')}</Link></div>
        }

        <Button variant="primary" type="submit" disabled={loading} className="mt-3">
          {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
          {t('continue')}
        </Button>

      </Form>

    </>
  );
};

export default PasswordStepComponent;
