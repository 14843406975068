import { AuthState, ISettings } from '../interfaces/auth.interface'

export type AuthAction =
    | { type: 'UPDATE_EMAIL'; payload: string }
    | { type: 'UPDATE_PASSWORD'; payload: string }
    | { type: 'LOADING'; payload: boolean }
    | { type: 'SETTINGS'; payload: ISettings }
    | { type: 'RESET_PASSWORD_SUCCESS'; payload: string }
    | { type: 'LOGOUT_SUCCESS' }



const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'UPDATE_EMAIL':
            return { ...state, email: action.payload, password: '', settings: {} as ISettings };
        case 'UPDATE_PASSWORD':
            return { ...state, password: action.payload };
        case 'LOADING':
            return { ...state, loading: action.payload };
        case 'SETTINGS':

            if (!state.settings.totalFailedAttempts) {
                state.settings.totalFailedAttempts = 0;
            }

            if (action.payload.error) {
                action.payload.totalFailedAttempts = state.settings.totalFailedAttempts
                action.payload.totalFailedAttempts++
            }

            return {
                ...state, settings: action.payload
            }
        case 'RESET_PASSWORD_SUCCESS':
            return { ...state, email: action.payload, password: '', settings: { validResetToken: true, resetSuccess: true } as ISettings };
        case 'LOGOUT_SUCCESS':
            return { ...state, settings: { logoutSuccess: true } as ISettings };
        default:
            return state;
    }
};

export default authReducer;
