import { Card } from "react-bootstrap"
import i18n from "../i18n"
import EmailStepComponent from '../components/loginSteps/emailStep.component'
import PasswordStepComponent from '../components/loginSteps/passwordStep.component'
import MfaStepComponent from '../components/loginSteps/mfaStep.component'
import useDocument from '../hooks/useDocument.hooks'

function LoginPage() {

    const { t } = i18n;

    useDocument({ title: t('_page_title.login') })

    return (
        <>
            <Card.Body>
                <Card.Title className="text-start"><h1>{t('_sign_in.title')}</h1></Card.Title>
                <Card.Text className="text-start">{t('_sign_in.description')}</Card.Text>

                <EmailStepComponent />
                <PasswordStepComponent />
                <MfaStepComponent />

            </Card.Body>
        </>
    )
}

export default LoginPage