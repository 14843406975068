import { ISettings } from '../interfaces/auth.interface';
import { AuthAction } from '../reducers/auth.reducer'

export const updateEmail = (payload: string): AuthAction => ({
    type: 'UPDATE_EMAIL',
    payload
});

export const updatePassword = (payload: string): AuthAction => ({
    type: 'UPDATE_PASSWORD',
    payload
});

export const setLoading = (payload: boolean): AuthAction => ({
    type: 'LOADING',
    payload
});

export const updateSettings = (payload: ISettings): AuthAction => ({
    type: 'SETTINGS',
    payload
});

export const resetPasswordSuccess = (payload: string): AuthAction => ({
    type: 'RESET_PASSWORD_SUCCESS',
    payload
});

export const logoutSuccess = (): AuthAction => ({
    type: 'LOGOUT_SUCCESS'
});
